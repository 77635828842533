import { intervalToDuration } from "date-fns";
import { useEffect, useState } from "react";

export default function TimeUntil({ time, title }) {
    const [until, setUntil]=useState();
    useEffect(() => {
        const interval = setInterval(() => {

          const startDate = new Date();
          const endDate = new Date(time);
          const duration = intervalToDuration({ start: startDate, end: endDate });
              setUntil(duration)






        }, 1000);
        return () => clearInterval(interval);
      }, []);
    return (  <div className="border rounded-lg bg-white bg-opacity-50 flex flex-col p-2 ">
      <div className="w-full text-2xl text-center uppercase pb-3">{title}</div>
        {until&& (
          <>
            <div className="w-full flex h-8 ">
            <div className="w-8 h-8 border text-center ">M</div>
            <div className="w-8 h-8 border text-center ">D</div>
            <div className="w-8 h-8 border text-center ">K</div>
            <div className="w-8 h-8 border text-center ">M</div>
            <div className="w-8 h-8 border text-center ">S</div>
            </div>
             <div className="w-full flex h-8 ">
             <div className="w-8 h-8 border text-center ">{until.months}</div>
             <div className="w-8 h-8 border text-center ">{until.days}</div>
             <div className="w-8 h-8 border  text-center">{until.hours}</div>
             <div className="w-8 h-8 border text-center ">{until.minutes}</div>
             <div className="w-8 h-8 border  text-center">{until.seconds}</div>
             </div>
             </>
            )}
            
            </div> 
    );
  }